import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import { ReactComponent as Arrow } from "../assets/icons/Arrow.svg";

import FaceScan from "../assets/images/FaceScan.png";
import FingerScan from "../assets/images/FingerScan.png";

const SelectScanType = () => {
  const { state } = useLocation();

  const nextRoute = useMemo(() => {
    if (state.posture?.length > 0 && /^(resting|standing|walking|exercising)$/.test(state.posture))
      return "/scan-instructions";
    else return "/select-posture";
  }, [state.posture]);

  return (
    <section className="p-8">
      <div className="flex items-center text-primary">
        <Link className="flex-shrink-0 p-1 pr-2.5" to="/basic-details" replace state={state}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">Select Scan Type</h3>
      </div>

      <div className="my-6 space-y-6">
        <Link
          className="p-6 rounded-xl flex space-x-6 bg-secondary/10"
          to={nextRoute}
          state={{ ...state, scan_type: "face" }}
        >
          <img className="flex-shrink-0 h-12 w-12" src={FaceScan} alt="face-scan" />
          <div>
            <h4 className="text-primary text-sm font-medium">Face Scan</h4>
            <p className="mt-1 text-darkgray text-xxs">
              60-seconds contactless vital scan using your smartphone's front camera. It is as simple as taking a
              selfie.
            </p>
            <div className="inline-flex items-center space-x-1 rounded-full mt-2 px-3 py-1 bg-secondary text-white">
              <span className="flex-shrink-0 text-xxs">Start Scan</span>
              <Arrow className="flex-shrink-0 h-2.5 w-2.5" />
            </div>
          </div>
        </Link>
        <Link
          className="p-6 rounded-xl flex space-x-6 bg-secondary/10"
          to={nextRoute}
          state={{ ...state, scan_type: "finger" }}
        >
          <img className="flex-shrink-0 h-12 w-12" src={FingerScan} alt="finger-scan" />
          <div>
            <h4 className="text-primary text-sm font-medium">Finger Scan</h4>
            <p className="mt-1 text-darkgray text-xxs">
              Place your finger on the rear camera of your smartphone to scan your vital signs in 60 seconds.
            </p>
            <div className="inline-flex items-center space-x-1 rounded-full mt-2 px-3 py-1 bg-secondary text-white">
              <span className="flex-shrink-0 text-xxs">Start Scan</span>
              <Arrow className="flex-shrink-0 h-2.5 w-2.5" />
            </div>
          </div>
        </Link>
      </div>

      <div className="mt-12 mb-6 p-6 rounded-xl bg-secondary text-white space-y-1.5">
        <h3 className="text-xs font-medium">Disclaimer</h3>
        <p className="text-xxs text-justify">
          For Investigational Use Only. Face scan is not a substitute for the clinical judgment of a healthcare
          professional. Face scan is intended to improve your awareness of general wellness. Face scan does not diagnose,
          treat, mitigate or prevent any disease, symptom, disorder or abnormal physical state. Consult with a health
          care professional or emergency services if you believe you may have a medical issue.
        </p>
      </div>
    </section>
  );
};

export default SelectScanType;
