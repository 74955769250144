import { RadioGroup } from "@headlessui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as GenderMale } from "../assets/icons/GenderMale.svg";
import { ReactComponent as GenderFemale } from "../assets/icons/GenderFemale.svg";
import { ReactComponent as GenderOthers } from "../assets/icons/GenderOthers.svg";

const BasicDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [gender, setGender] = useState(state.gender ?? "");
  const [dob, setDOB] = useState(state.dob ?? "");
  const [height, setHeight] = useState(state.height?.toString?.() ?? "");
  const [weight, setWeight] = useState(state.weight?.toString?.() ?? "");
  const [error, setError] = useState("");

  const checkForm = (e) => {
    e.preventDefault();
    try {
      if (dob?.length > 0) {
        const age = Math.abs(new Date(Date.now() - new Date(dob).getTime()).getUTCFullYear() - 1970);
        if (age < 18 || age > 110) throw new Error("Age must be between 18 and 110");
      } else throw new Error("Invalid Date of Birth");

      if (!(gender?.length > 0 && /^(male|female|others)$/.test(gender))) throw new Error("Invalid Gender");

      if (height?.length > 0 && /^\d{1,3}(\.\d{1,2})?$/.test(height)) {
        if (height < 30 || height > 250) throw new Error("Height must be between 30 and 250 cms");
      } else throw new Error("Invalid Height");

      if (weight?.length > 0 && /^\d{1,3}(\.\d{1,2})?$/.test(weight)) {
        if (weight < 20 || weight > 220) throw new Error("Weight must be between 20 and 220 kgs");
      } else throw new Error("Invalid Weight");

      if (state.scan_type?.length > 0 && /^(face|finger)$/.test(state.scan_type)) {
        if (state.posture?.length > 0 && /^(resting|standing|walking|exercising)$/.test(state.posture))
          navigate("/scan-instructions", { state: { ...state, dob, gender, height, weight } });
        else navigate("/select-posture", { state: { ...state, dob, gender, height, weight } });
      } else navigate("/select-scan-type", { state: { ...state, dob, gender, height, weight } });
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <section className="p-8">
      <h3 className="text-primary text-sm font-medium">Basic Details</h3>

      <form className="my-6" onSubmit={checkForm}>
        <div className="mb-3">
          <label htmlFor="dob" className="block text-primary/60 text-xs">
            Date of Birth
          </label>
          <input
            className="block w-full mt-2 px-4 py-2 rounded-lg border border-lightgray/20 outline-none focus:border-secondary text-primary text-xs"
            id="dob"
            name="dob"
            type="date"
            max={new Date().setFullYear(new Date().getFullYear() - 18)}
            min={new Date().setFullYear(new Date().getFullYear() - 110)}
            autoComplete="off"
            value={dob}
            onChange={(e) => setDOB(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="height" className="block text-primary/60 text-xs">
            Height (in cms)
          </label>
          <input
            className="block w-full mt-2 px-4 py-2 rounded-lg border border-lightgray/20 outline-none focus:border-secondary text-primary text-xs"
            id="height"
            name="height"
            type="text"
            inputMode="numeric"
            autoComplete="off"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="weight" className="block text-primary/60 text-xs">
            Weight (in kgs)
          </label>
          <input
            className="block w-full mt-2 px-4 py-2 rounded-lg border border-lightgray/20 outline-none focus:border-secondary text-primary text-xs"
            id="weight"
            name="weight"
            type="text"
            inputMode="numeric"
            autoComplete="off"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
        </div>
        <RadioGroup name="gender" value={gender} onChange={setGender}>
          <RadioGroup.Label className="text-primary/60 text-xs">Gender</RadioGroup.Label>
          <div className="mt-2 grid grid-cols-3 gap-4">
            {[
              { value: "male", icon: <GenderMale className="flex-shrink-0 h-4 w-4" /> },
              { value: "female", icon: <GenderFemale className="flex-shrink-0 h-4 w-4" /> },
              { value: "others", icon: <GenderOthers className="flex-shrink-0 h-4 w-4" /> },
            ].map(({ value, icon }) => (
              <RadioGroup.Option
                key={`gender-${value}`}
                value={value}
                className={({ checked }) =>
                  `flex items-center justify-center space-x-2.5 p-2 border rounded-lg ${
                    checked ? "border-secondary bg-secondary/5 text-secondary" : "border-lightgray/20 text-primary"
                  } cursor-pointer`
                }
              >
                {icon}
                <RadioGroup.Label className="text-xs capitalize">{value}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
        {error.length > 0 && (
          <p className="mt-4 px-3 py-1.5 rounded-lg flex items-center bg-red-200 text-red-600">
            <svg className="h-4 w-4 flex-shrink-0" viewBox="0 0 48 48" fill="currentColor">
              <path d="M24 34q.7 0 1.175-.475.475-.475.475-1.175 0-.7-.475-1.175Q24.7 30.7 24 30.7q-.7 0-1.175.475-.475.475-.475 1.175 0 .7.475 1.175Q23.3 34 24 34Zm-1.35-7.65h3V13.7h-3ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z" />
            </svg>
            <span className="ml-2 text-xs font-light">{error}</span>
          </p>
        )}
        <button
          className="mt-8 mx-auto w-40 flex items-center justify-center space-x-2 rounded-full px-4 py-2 bg-secondary disabled:bg-darkgray text-white text-xs font-medium"
          type="submit"
        >
          Next
        </button>
      </form>
    </section>
  );
};

export default BasicDetails;
