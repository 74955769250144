import { Cell, Pie, PieChart } from "recharts";

const ProgressCircle = ({ size = 100, color = "#DFEBF5", value = undefined, title, subtitle }) => {
  return (
    <div className="inline-block bg-[#DFEBF5] rounded-full relative">
      <PieChart width={size} height={size} margin={{ top: 0, bottom: 0, left: 0, right: 0 }}>
        <Pie
          data={[{ value: value ?? 0 }, { value: 100 - (value ?? 0) }]}
          dataKey="value"
          startAngle={270}
          endAngle={-90}
          innerRadius="90%"
          outerRadius="100%"
        >
          <Cell fill={color} stroke="none" />
          <Cell fill="transparent" stroke="none" />
        </Pie>
      </PieChart>
      <div className="absolute top-[5%] bottom-[5%] left-[5%] right-[5%] rounded-full bg-white">
        <div
          className="absolute top-[7.5%] bottom-[7.5%] left-[7.5%] right-[7.5%] rounded-full flex flex-col items-center justify-center"
          style={{ backgroundColor: typeof value === "number" ? color : "#DFEBF5" }}
        >
          <h3 className="text-base" style={{ color: typeof value === "number" ? "#fff" : "#B4C7D7" }}>
            {typeof value === "number" ? (title?.length > 0 ? title : `${value}%`) : "NA"}
          </h3>
          <h5 className="text-xxs" style={{ color: typeof value === "number" ? "#fff" : "#B4C7D7" }}>
            {typeof value === "number" ? subtitle : "--"}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default ProgressCircle;
