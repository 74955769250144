import {
  // Navigate,
  createBrowserRouter,
} from "react-router-dom";

import NotFound from "./screens/404";
import StartScan from "./screens/StartScan";
import BasicDetails from "./screens/BasicDetails";
import SelectScanType from "./screens/SelectScanType";
import ScanInstructions from "./screens/ScanInstructions";
import SelectPosture from "./screens/SelectPosture";
import FaceScan from "./screens/FaceScan";
import FingerScan from "./screens/FingerScan";
import ScanResult from "./screens/ScanResult";

export const router = createBrowserRouter([
  { path: "*", element: <NotFound /> },
  // {
  //   index: true,
  //   path: "/",
  //   element: (
  //     <Navigate
  //       to={`start-scan?${new URLSearchParams({
  //         dob: "1998-04-25",
  //         gender: "Male",
  //         height: 174,
  //         weight: 65,
  //         scan_type: "Finger",
  //         // posture: "Walking",
  //         // scan_token: "abc",
  //         is_webview: false,
  //         show_result: true,
  //         callback_url: "https://www.google.com/asdfg/qwer/",
  //         partner_client_id: "PC001",
  //         partner_key: "CAREPLIXUSER",
  //         partner_secret: "bOfzducVmyf37wZJ9ASsAqVlUkaEXpqqMjWqUQlF",
  //       }).toString()}`}
  //       replace
  //     />
  //   ),
  // },
  { path: "start-scan", element: <StartScan /> },
  { path: "basic-details", element: <BasicDetails /> },
  { path: "select-scan-type", element: <SelectScanType /> },
  { path: "select-posture", element: <SelectPosture /> },
  { path: "scan-instructions", element: <ScanInstructions /> },
  { path: "face-scan", element: <FaceScan /> },
  { path: "finger-scan", element: <FingerScan /> },
  { path: "scan-result", element: <ScanResult /> },
]);
